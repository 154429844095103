<script setup>
    // OrderHistoryPanel
    // Shows order history in the my account area.
    //import { ref, watch } from 'vue';
    import { useRoute } from 'vue-router';
    // Components ----
    import OrderHistoryTile from '@/site/components/OrderHistoryTile';
    import MockProductListTile from '@/site/components/MockProductListTile';
    import { Search } from '@dd-nucleus/nucleus-vue';

    // Imports ----

    // State ----
    const pageSizeOptions = [12, 24, 36, 48, 60];

    const route = useRoute();

    const search = new Search('orders-for-user', 'tile', "order-newest-to-oldest", route.query['per-pg'] ?? 12);
    search.setFilterValues('include-firstOrder-in-bulkOrders', [''])


</script>

<template>
    <div class="account-panel order-history-panel">
        <h1 class="cart-page-header mb-3">My Account <span class="text-muted">/ My Order History</span></h1>

        <SearchContainer :search="search" default-view="list" :deep-link="true">
            <div class="filter-bar mb-3">
                <div class="mb-2">
                    <TextBoxFilter filter-id="order-history-search-term" placeholder="Search orders by no, name, or email" />
                </div>

                <div>
                    <DateRangeFilter :useOnlyDate="true" filter-id="order-date-range" />
                </div>
            </div>

            <div class="d-flex mb-3">
                <div><PageSizeList :options="pageSizeOptions" /></div>
                <div class="flex-fill text-end"><PageSelector /></div>
            </div>

            <SearchGrid>
                <template v-slot:list="order">
                    <OrderHistoryTile :order="order" />
                </template>

                <template #empty>
                    <div>
                        There is no order history currently available for your account. For more help please <router-link class="" to="/help/contact">Contact Us.</router-link>
                    </div>
                </template>

                <template #loading-list>
                    <MockSearchGrid>
                        <MockProductListTile />
                    </MockSearchGrid>
                </template>
            </SearchGrid>

            <div class="d-flex">
                <div class="flex-fill text-end"><PageSelector /></div>
            </div>
        </SearchContainer>
    </div>
</template>

<style lang="scss">
    .order-history-panel {
        .n-search-grid .tile {
            margin-bottom: 0.5rem;
            padding: 0.5rem;
        }

        .n-search-grid .tile:nth-child(odd) {
            background-color: #efefef;
        }
    }
</style>
