<script setup>

// ShipToContact
// Allows the user to select a single contact as the ship-to destination.

// Components ----
import CRMContacts from '@/site/components/crm/CRMContacts';

// Imports ----
import { ref, onMounted, defineProps, defineEmits, reactive, computed } from 'vue';
import { store } from '@/Store';
import { useRouter } from 'vue-router';

import ConfirmAddressList from '@/site/areas/cart/components/ConfirmAddressList';
import ShipToUserForm from '../forms/ShipToUserForm';


const props = defineProps({
    // If true, this will be styled for selecting multiple items.
    multiSelect: {
        type: Boolean,
        required: false,
        default: false
    },
    // Indicates whether the selection can be toggled on/off
    toggle: {
        type: Boolean,
        required: false,
        default: false
    }
});

const router = useRouter();
const haveAddressesUpdated = ref(false);

// Constants ----
const Display = {
    SUMMARY_ADDRESS_LIST: 'SUMMARY_ADDRESS_LIST',
    PREVIEW_ADDRESS_LIST: 'PREVIEW_ADDRESS_LIST',
    CONFIRM_ADDRESS_LIST: 'CONFIRM_ADDRESS_LIST',
    UPLOAD_ADDRESS_LIST: 'UPLOAD_ADDRESS_LIST'
};

// State ----
const selection = ref([]);
// Events ----
const emit = defineEmits(['selected', 'deselected']);
// Computed ----
const isContactsSelected = ref([])

const display = ref(Display.UPLOAD_ADDRESS_LIST);
const form = reactive(new ShipToUserForm());

const updatedAddress = ref([])

const properties = [
    'addressee',
    'companyName',
    'addressLine1',
    'addressLine2',
    'addressLine3',
    'addressLine4',
    'city',
    'state',
    'postalCode',
    'countryCode'
];

// Handlers ----
onMounted(() => {
    isContactsSelected.value = false;
    update();
});

store.cart.onCartLoaded(() => {
    update();
});

store.cart.onCartUpdated(() => {
    update();
});

function update() {
    if (typeof store.cart.current.shipToList === 'undefined') return;
    if (store.cart.current.shipToMode === store.cart.ShipToMode.ShipToContact) {
        selection.value.push(store.cart.current.shipToList[0]?.marketingContactId);
    }
    else {
        selection.value = [];
        for (let contact of store.cart.current.shipToList) {
            if (contact.marketingContactId !== null && contact.marketingContactId !== undefined)
                selection.value.push(contact.marketingContactId);
        }
    }
}

const personalAddresses = computed(() => {
    return updatedAddress.value;
})


function onCancelClicked() {
    display.value = Display.UPLOAD_ADDRESS_LIST;
}

function onContactSelected(contact) {
    updatedAddress.value = contact;
    isContactsSelected.value = true;
    if (store.cart.current.shipToMode === store.cart.ShipToMode.ShipToContacts) {
        selection.value.push(contact.id);
        emit('selected', contact);
    }
    else {
        if (props.multiSelect) {

            selection.value = []
            selection.value.push(contact.id);
        }
        store.cart.setShipToMarketingContact(contact.id);
        store.refreshSearches('crm-addresses-for-user');
    }
}

function onContactDeselected(contact) {
    if (store.cart.current.shipToMode === store.cart.ShipToMode.ShipToContacts) {
        selection.value = selection.value.filter(x => x !== contact.id);
        emit('deselected', contact);
    }
}

async function goToReviewPage(shipToContacts) {
    if (!haveAddressesUpdated.value) {
        await store.cart.setShipToMarketingContact(shipToContacts.id);
    }

    if (shipToContacts !== null || shipToContacts !== undefined) {
        await updateToSingleContact(shipToContacts);
        router.push({ name: 'Review' });
        return;
    }
}


async function updatedAddresses(updatedAddresses) {
    haveAddressesUpdated.value = true;
    await store.cart.setShipToMultiplContacts(updatedAddresses);
}


async function updateToSingleContact(shipToContact) {

    properties.forEach(property => {
        form.model[property] = shipToContact[property];
    });

    await store.cart.setShipToModeToSingleContact()

}

function onMyAddressConfirmClicked() {
    {
        display.value = Display.CONFIRM_ADDRESS_LIST;

        var selectedAddresses = store.cart.current.shipToList.filter(a => a.marketingContactId !== null && a.marketingContactId !== undefined).map((address) => {
            return {
                ...address,
                id: address.marketingContactId
            }
        });

        store.cart.setShipToMarketingContact(selectedAddresses[0].marketingContactId);
    }
}

</script>

<template>

    <div class="ship-to-contact">
        <template v-if="display === Display.CONFIRM_ADDRESS_LIST">
            <ConfirmAddressList :addressList="personalAddresses" @update="updatedAddresses">
                <template #empty>
                    Empty Addresses
                </template>
            </ConfirmAddressList>

            <div class="show-right">
                <PageButton class="me-2" :outline="true" @click="onCancelClicked">Back</PageButton>
                <PageButton class="me-2" @click="goToReviewPage(personalAddresses)">Review</PageButton>
                <!--:disabled="personalAddresses.length === 0"-->

            </div>
        </template>

        <template v-else>
            <CRMContacts @selected="onContactSelected" @deselected="onContactDeselected" :selection="selection"
                :can-select="true" :allow-create="true" :multiSelect="props.multiSelect" :toggle="props.toggle" />
            <div v-if="!props.multiSelect" class="show-right">
                <PageButton class="me-2" :disabled="!isContactsSelected" @click="onMyAddressConfirmClicked">Confirm
                    Addresses</PageButton>
            </div>
        </template>
    </div>

</template>

<style lang="scss">
.ship-to-contact {}

.show-right {
    float: right
}
</style>