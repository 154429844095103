<script>
	// ProductGridTile
	// A tile for a single product intended to be displayed in grid mode.

	// Setup ---
	// Use the values below to modify how the tile appears
	const setup = {
		tileHeight: '25rem', // Height of the full tab, fixed for alignment purposes
		productNameLines: 2 // Number of lines product name is allowed to wrap before being trimmed with ellipses
	};

	export const Setup = setup;
</script>

<script setup>
	// Components ----
	import LimitMessages from './LimitMessages';

	// Imports ----
	import { computed, reactive, defineProps } from 'vue';
	import { AddToCartForm } from '@dd-nucleus/nucleus-vue';
	import { store } from '@/Store';

	// Props ----
	const props = defineProps({
		// The product whose information to show.
		// API model: DD.Nucleus.Storefront.Api.ProductTileModel
		product: {
			type: Object,
			required: true
		}
	});

	// State ----
	const product = reactive(props.product);
	const form = reactive(new AddToCartForm(props.product, '', null, product.allowBackorders));

	// Computed ----
	//const showInStockQuantity = computed(() => {
	//	return product.limits == null || product.limits.length == 0;
	//});

	const quantityLabel = computed(() => {
		return store.config.store?.unitOfMeasures.find(unit => unit.id?.toLowerCase() === product.unitOfMeasure?.toLowerCase())?.text ?? null;
	});

	// Handlers ----
</script>

<template>
	<div class="product-grid-tile d-flex flex-column" :style="{ minHeight: setup.tileHeight }">
		<div class="top">
			<ProductThumbnail :product="product" height="200px" no-image-available-url="/images/no-image.png" />
		</div>
		<div class="middle flex-fill">
			<!-- Stretchy middle part-->

			<div class="d-flex flex-row">
				<div class="flex-fill">
					<ProductTags :product="product" />
				</div>
				<div>
					<ProductFavoriteIcon :product="product" />
				</div>
			</div>

			<!-- Product name and item number -->
			<router-link class="title-link" :to="`/product/${product.id}`">
				<div class="name" v-snip="{ lines: setup.productNameLines }" :title="product.name">
					{{ product.name }}
				</div>
			</router-link>
			<div class="item-number">
				<router-link class="title-link" :to="`/product/${product.id}`">{{ product.itemNumber }}</router-link>
			</div>

			<LimitMessages :product="product" class="mt-2" />

			<!--<span v-if="showInStockQuantity && !product.allowBackorders" class="filter-messages">Qty in stock: {{ product.quantityAvailable }} </span>-->
		</div>
		<div class="bottom d-flex coming-soon" v-if="product.isAvailableSoon">
			<i class="bi bi-hourglass-split"></i>
			<i><p>Coming soon...</p></i>
		</div>
		<div class="bottom" v-else>
			<!-- Fixed bottom part -->
			<MessageList :messages="product.messages" :add-box="false" v-if="!product.allowBackorders" />
			<p>Quantity: {{ product.quantityPerUnit }} {{ product.quantityPerUnit > 1 ? 'units' : 'unit' }} per {{ quantityLabel }}</p>
			<FormContainer :form="form" v-if="product.canAddToCart">
				<FormMessages field-name="quantity" :add-box="false" class="mb-2" v-if="!product.allowBackorders" />
				<FieldMessages field-name="quantity" class="mb-2" />
				<ProductAddToCartQty :product="product" />
				<div class="d-grid mt-3">
					<button class="btn btn-outline-color-1" @click="form.submit()" :disabled="form.status.isInvalid">ADD TO CART</button>
				</div>
			</FormContainer>
		</div>
	</div>
</template>

<style lang="scss">
    .product-grid-tile {

        .coming-soon {
            justify-content: center;
            align-self: center;

            .bi-hourglass-split {
                font-size: 1rem;
                margin-right: 0.5rem;
            }

            p {
                font-size: 14px;
                margin-bottom: 0;
            }
        }

        p {
            font-size: 12px;
        }
        .name {
            font-size: 1rem;
            line-height: 1.1rem;
        }

        a.title-link,
        a.title-link:visited,
        a.title-link:hover {
            color: var(--bs-body-color);
            text-decoration: none;
        }

        .item-number a.title-link {
            font-size: 0.7rem;
            color: $gray-50;
        }

        .n-product-add-to-cart-qty .text-box {
            border-color: #dddddd;
        }

        .btn[disabled] {
            opacity: 0.2;
        }
    }
</style>
