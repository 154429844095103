<script setup>
	// SignInForm
	// Provides username and password to sign in.

	// Components ----
/*	import Popper from 'vue3-popper';*/

	// Imports ----
	import { reactive } from 'vue';
	import { useRouter, useRoute } from 'vue-router';
	import SignInForm from '../forms/SignInForm';
    import { store } from '@/Store';

	var router = useRouter();
	var route = useRoute();

	// State ----
	const form = reactive(new SignInForm(router));

	// Dispatch the sign in request to the registered authenticator
	// Add token to the request during login.
	// This will invalidate previous tokens and generate new one, to avoid parallel session concurrency
	async function onSignIn() {
        sessionStorage.setItem('logged-in', 'true');
		await form.submit();
        await store.addToken();
	}

    function onOktaSignIn() {
        form.onOktaSignIn();
    }

</script>

<template>
	<FormContainer :form="form">
		<div v-if="route.query.timeout === 'true'" class="alert alert-warning">
			<i class="bi bi-exclamation-circle-fill"></i> &nbsp;Uh oh! Your last session was timed out due to inactivity. Please sign in again.
		</div>

		<div v-if="route.query.signout === 'true'" class="alert alert-success">
			<i class="bi bi-check-circle-fill"></i> &nbsp;You have successfully signed out. Please sign in again to continue.
		</div>

		<div v-if="route.query.created === 'true'" class="alert alert-success">
			<i class="bi bi-check-circle-fill"></i> &nbsp;Your account has been created. A verification link has been sent to your email.
		</div>

		<FormMessages class="mb-2" />

		<div class="mb-3">
			<FieldLabel field-name="username" label="Email">
				<TextBoxField v-model="form.model.username" placeholder="Email Address" :auto-focus="true" @keyup.enter="onSignIn" />
				<FieldMessages field-name="username" />
			</FieldLabel>
		</div>
		<div class="mb-3">
			<FieldLabel field-name="password" label="Password">
				<TextBoxField v-model="form.model.password" placeholder="Password" :is-password="true" show-pwd-btn-class="btn-color-2-xlt" @keyup.enter="onSignIn" />
			</FieldLabel>
		</div>
		<!--<CheckBoxField v-model="form.model.keepSignedIn">
			Keep me signed in.
			<Popper arrow>
				<a href="#">Details</a>
				<template #content>
					<div class="popper-content">
						By checking this box, you won't have to sign in as often on this device. For your security we recommend only checking this box on your personal device.
					</div>
				</template>
			</Popper>
		</CheckBoxField>-->
		<div class="d-grid mt-3">
			<button class="btn btn-color-1" @click="onSignIn" :disabled="form.status.isInvalid">SIGN IN</button>
		</div>
		<div class="d-grid mt-3">
			<button class="btn btn-color-1" @click="onOktaSignIn">SIGN IN WITH ALCON ACCOUNT</button>
		</div>

	</FormContainer>
</template>

<style lang="scss">
	.n-text-box-field {
		.label {
			font-weight: $bold;
		}
	}

	.popper-content {
		width: 200px;
	}
</style>
