<script setup>
import { onMounted, reactive } from 'vue';
import Faqs from './Faqs';

const faqs = new Faqs();

const faqsList = reactive([]);

onMounted(async () => {
    // Keeping the default value for now. This can be changed to a dynamic value later.
    faqsList.push(...faqs.faqsToShow('default'));
}); 

</script>
<template>
    <div class="faqs-table table-responsive">
        <div class="tab-content mt-4">
            <div class="tab-pane fade show active faqs-table table-responsive" role="tabpanel"
                aria-labelledby="info-tab" tabindex="0">
                <table class="table table-striped mw-100">
                    <tbody>
                        <tr v-for="faq in faqsList" :key="faq.id">
                            <p><strong v-html="faq.ques"></strong></p>
                            <td>
                                <p v-html="faq.ans"></p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

    </div>
</template>
<style lang="scss">
.faqs-table .table-responsive {
    table {
        width: 100%;
        table-layout: fixed;

        tbody {
            display: flex;
            flex-direction: column;
            gap: 15px;

            strong {
                color: #1a1446;
            }

            p {
                margin-bottom: 0 !important;
            }

            tr {
                display: flex;
                flex-direction: column;

                td {
                    white-space: normal !important;
                    word-wrap: break-word;
                    padding-top: 0 !important;

                    ul {
                        margin-top: 10px;
                        padding-left: 25px !important;
                    }

                    p {
                        font-size: 14px !important;
                    }
                }
            }
        }
    }
}
</style>
