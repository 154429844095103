<script setup>

    // OrderHistoryTile
    // A tile used for each order in order history.

    // Components ----

    // Imports ----
    import { defineProps, computed } from 'vue';
    import { Util } from '@dd-nucleus/nucleus-vue';

    // Props ----
    const props = defineProps({
        // The order whose information to show.
        // API model: DD.Nucleus.Storefront.Api.OrderTileModel
        order: {
            type: Object,
            required: true
        }
    });

    // Computed ----
    const icon = computed(() => {
        // TODO: Hacked in. Create constants in Orders service plugged into singleton store object.
        switch (props.order.orderStatusCode) {
            default: // None
                return '';
            case 1: // Pending
                return 'bi bi-check-circle-fill';
            case 2: // PendingApproval
                return 'bi bi-pause-circle-fill';
            case 99: // Canceled
                return 'bi bi-x-circle-fill';
            case 100: // Completed
                return 'bi bi-check-circle-fill';
        }
    });

    const cssClass = computed(() => {
        // TODO: Hacked in. Create constants in Orders service plugged into singleton store object.
        switch (props.order.orderStatusCode) {
            default: // None
                return '';
            case 1: // Pending
                return 'pending';
            case 2: // PendingApproval
                return 'pending-approval';
            case 99: // Canceled
                return 'canceled';
            case 100: // Completed
                return 'completed';
        }
    });

    // Methods ----
    function shipToText(order) {
        let text = '';

        if (Util.isNotEmpty(order.shipTo.addressee))
            text += order.shipTo.addressee;
        else
            text += order.shipTo.attention;

        if (Util.isNotEmpty(order.shipTo.companyName))
            text += ' - ' + order.shipTo.companyName;

        if (Util.isNotEmpty(order.bulkOrderNumber))
            text = '';

        return text;
    }

</script>

<template>

    <div class="order-history-tile d-flex">
        <div class="flex-fill">
            <div class="title" v-if="order.bulkOrderNumber"><span class="order-number">Order #{{ order.bulkOrderNumber }}</span> | {{ Util.date(order.orderDate) }} </div>
            <div class="title" v-else><span class="order-number">Order #{{ order.orderNumber }}</span> | {{ Util.date(order.orderDate) }} </div>

            <div class="shipto">{{ shipToText(order) }}</div>
            <div v-if="order.shipments.length > 0" class="shipments">
                <div v-for="shipment in order.shipments" :key="shipment.id" class="shipment">
                    Shipped {{ Util.date(shipment.shipDate) }}
                    <span v-if="Util.isNotEmpty(shipment.trackingUrl)"></span>: <a :href="shipment.trackingUrl" target="_blank">{{ shipment.trackingNumber }}</a>
                </div>
            </div>
            <div class="status" :class="cssClass"><i :class="icon"></i> {{order.orderStatus }}</div>
            <div class="status" v-if="order.bulkOrderNumber"><span>Multi-Address Order</span></div>
        </div>
        <div class="text-end action-panel">
            <router-link v-if="order.bulkOrderNumber" :to="'/order/'+ order.bulkOrderId">View Details</router-link>
            <router-link v-else :to="'/order/'+ order.id">View Details</router-link>
        </div>
    </div>

</template>

<style lang="scss">

    .order-history-tile {
        .order-number {
            font-weight: $bold;
        }

        .status {
            margin-top: 0.5rem;
            font-weight: $bold;
            font-size: 0.7rem;
        }

        .status.pending {
            .bi {
                opacity: 0.5;
                color: $ok;
            }
        }

        .status.pending-approval {
            .bi {
                color: $warning;
            }
        }

        .status.canceled {
            .bi {
                color: $error;
            }
        }

        .status.completed {
            .bi {
                color: $ok;
            }
        }
        // TODO: This is hacked in for now. Look at global link styling.
        .action-panel a, .action-panel a:visited {
            color: $color-2;
            text-decoration: none;
        }

        .action-panel a:hover {
            text-decoration: underline;
        }

        .shipments .shipment {
            color: $ok;
            font-weight: bold;
            font-size: 0.7rem;
            margin-top: 0.5rem;
        }
    }
</style>