<script setup>

// ShipToContacts
// Allows the user to select multiple contacts as the ship-to, generating a bulk order.


// Import ----
import { ref, computed, reactive, defineProps } from 'vue';
import { useRouter } from 'vue-router';
import { store } from '@/Store';

import ShipToCRMContact from './ShipToCRMContact';
import ConfirmAddressList from '@/site/areas/cart/components/ConfirmAddressList';
import ShipToUserForm from '../forms/ShipToUserForm';

// Props ----
const props = defineProps({
    disableContinue: {
        type: Boolean,
        required: false,
        default: false
    },
    reasonforexpedited: {
        type: String,
        required: false,
        default: ''
    },
    isExpeditedReasonRequired: {
        type: Boolean,
        required: false,
        default: false
    },
    isSunShineActReportable: {
        type: Boolean,
        required: false,
        default: false
    },
    doctorName: {
        type: String,
        required: false,
        default: ''
    },
    doctorEmail: {
        type: String,
        required: false,
        default: ''
    }
});

// Constants ----
const Display = {
    SUMMARY_ADDRESS_LIST: 'SUMMARY_ADDRESS_LIST',
    PREVIEW_ADDRESS_LIST: 'PREVIEW_ADDRESS_LIST',
    CONFIRM_ADDRESS_LIST: 'CONFIRM_ADDRESS_LIST',
    UPLOAD_ADDRESS_LIST: 'UPLOAD_ADDRESS_LIST'
};

const router = useRouter();

const AddOption =
{
    NEW_CONTACT: 'NEW_CONTACT',
    FROM_CONTACTS: 'FROM_CONTACTS',
    UPLOAD_FILE: 'UPLOAD_FILE'
};


// State ----
const display = ref(Display.UPLOAD_ADDRESS_LIST);
const selectedOption = ref(AddOption.UPLOAD_FILE);
const form = reactive(new ShipToUserForm());


const selectedAddresses = ref([]);
let showUploadFile = ref(false);

const personalAddresses = computed(() => {
    return selectedAddresses.value;
})

//computed

const isContactsSelected = computed(() => {
    return selectedAddresses.value.length > 0;
})
const haveAddressesUpdated = ref(false);

// Handlers ----
store.cart.onCartLoaded(() => {
    update();
});

store.cart.onCartUpdated(() => {
    update();
});

function update() {
    selectedAddresses.value = store.cart.current.shipToList.filter(t => !t.marketingContactId).map((address) => {
        return {
            ...address,
            id: address.marketingContactId
        }
    });
}

function onMyAddressConfirmClicked() {
    selectedAddresses.value = selectedAddresses.value.filter(a => (a.id !== null && a.id !== undefined) || (a.marketingContactId !== null && a.marketingContactId !== undefined));
    // Pass only valid addresses
    display.value = Display.CONFIRM_ADDRESS_LIST;
    // upload the address here
    updatedAddresses(selectedAddresses.value);
}

function onCancelClicked() {
    display.value = Display.UPLOAD_ADDRESS_LIST;
    selectedOption.value = AddOption.UPLOAD_FILE;
    showUploadFile.value = false;
}

async function updatedAddresses(updatedAddresses) {
    haveAddressesUpdated.value = true;
    await store.cart.setShipToMultiplContacts(updatedAddresses);
}

async function goToReviewPage(shipToContacts) {
    SaveReasonforExpedited();
    if (props.isSunShineActReportable) {
        await SaveDoctorsDetails();
    }

    if (!haveAddressesUpdated.value) {
        await store.cart.setShipToMultiplContacts(shipToContacts);
    }

    if (shipToContacts.length == 1) {
        await updateToSingleContact(shipToContacts);
        router.push({ name: 'Review' });
        return;
    }

    if (await store.cart.updateMultiShipItemsQuantity()) {
        router.push({ name: 'Review' });
    }
}

async function SaveReasonforExpedited() {
    if (props.reasonforexpedited.length > 0 && props.isExpeditedReasonRequired === true) {
        const reasonNote = {
            holdReasonNote: props.reasonforexpedited.trim(),
            holdReasons: "Expedited Shipping"
        }
        await store.cart.addHoldReasonNote(store.cart.current.id, reasonNote);
    }
}

async function SaveDoctorsDetails() {

    if (props.doctorName.length > 0 && props.isSunShineActReportable === true) {
        const doctorDetails = {
            doctorName: props.doctorName.trim(),
            doctorEmail: (props.doctorEmail.trim() !== null) ? props.doctorEmail.trim() : ""
        }
        await store.cart.addDoctorDetails(store.cart.current.id, doctorDetails);
    }
}


async function updateToSingleContact(shipToContacts) {
    const shipTo = shipToContacts[0];
    form.commonProps(form, shipTo);
    await store.cart.setShipToModeToSingleContact();

    await form.submit(false);
}

function onAddressSelected(address) {
    if (address.addressee.length < 1 && address.firstName && address.lastName) {
        address.addressee = address.firstName + " " + address.lastName;
    }
    selectedAddresses.value.push(address);
}

function onAddressDeselected(address) {
    selectedAddresses.value = selectedAddresses.value.filter(x => x.id !== address.id);
}


</script>

<template>

    <div class="ship-to-contacts">
        <template v-if="display === Display.CONFIRM_ADDRESS_LIST">
            <ConfirmAddressList :addressList="personalAddresses" @update="updatedAddresses">
                <template #empty>
                    Empty Addresses
                </template>
            </ConfirmAddressList>

            <div class="show-right">
                <PageButton class="me-2" :outline="true" @click="onCancelClicked">Back</PageButton>
                <PageButton class="me-2" :disabled="personalAddresses.length === 0 || props.disableContinue"
                    @click="goToReviewPage(personalAddresses)">Review</PageButton>
                <!--:disabled="personalAddresses.length === 0"-->
            </div>
        </template>

        <template v-else>
            <ShipToCRMContact :can-edit="true" :multiSelect="true" :toggle="true"
                :reasonforexpedited="props.reasonforexpedited"
                :isExpeditedReasonRequired="props.isExpeditedReasonRequired"
                :isSunShineActReportable="props.isSunShineActReportable" 
                :doctorName="props.doctorName"
                :doctorEmail="doctorEmail"
                @selected="onAddressSelected"
                @deselected="onAddressDeselected" />
            <div class="show-right">
                <PageButton class="me-2" :disabled="!isContactsSelected || props.disableContinue"
                    @click="onMyAddressConfirmClicked">Confirm
                    Addresses</PageButton>
            </div>
        </template>
    </div>

</template>

<style lang="scss">
.ship-to-contacts {
    .add-contacts {
        .sub-title {
            font-weight: $bold;
        }
    }

    .show-right {
        float: right
    }


    .add-options {}

    .add-option {
        border: 1px solid $gray-20;
        padding: 0.5rem;
        height: 10rem;
    }
}
</style>