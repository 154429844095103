<script setup>

    // ContactUsPage
    // Page providing contact information and a form.

    // Components ----
    import ContactUsPanel from './components/ContactUsPanel';
    import FAQsPanel from './components/FAQsPanel.vue';

    // Imports ----
    import { watch, ref } from 'vue';
    import { useRoute } from 'vue-router';

    const route = useRoute();

    // State ----
    let section = ref(route.params.section);

    // Update section when route (URL) changes
    watch(() => route.params, () => { section.value = route.params.section });

    // Methods ----
    function getLinkClass(forSection) {
        return { 'active': forSection === section.value };
    }

</script>

<template>
    <PageTemplate>
        <div class="container root-page">

            <div class="row">
                <div class="d-none d-sm-block col-sm-4 col-lg-3">
                    <div class="top-link side-link">Help Center</div>
                    <div class="sub-link side-link" :class="getLinkClass('contact')"><router-link to="contact">Contact Us</router-link></div>
                    <div class="sub-link side-link" :class="getLinkClass('contact')"><a target="_blank" class="dropdown-item" href="/downloads/OrderPointe EStore User Manual for Alcon_final.pdf">User Guide</a></div>
                    <div class="sub-link side-link" :class="getLinkClass('faq')"><a href="/help/faqs">Frequently Asked Questions</a></div>
                </div>
                <div class="col">

                    <div v-if="section === 'contact'">
                        <!-- My Profile -->
                        <h1 class="help-page-header mb-3">Contact Us</h1>
                        <div class="panel-container">
                            <ContactUsPanel />
                        </div>
                    </div>
                    <div v-else-if="section === 'faqs'">
                        <h1 class="help-page-header mb-3">Frequently Asked Questions</h1>
                        <div class="panel-container">
                            <FAQsPanel />
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </PageTemplate>
</template>

<style lang="scss">

    .side-link {
        padding-top: 0.5rem;

        &.active {
            font-weight: $bold;
        }

        a, a:visited {
            color: $color-2;
            text-decoration: none;
        }
    }

    .top-link {
        padding-top: 0rem;
    }

    .sub-link {
        padding-left: 1rem;
    }
</style>