<script setup>
    // Imports ----
    import { defineProps, computed, defineEmits } from 'vue';

    // Props ----
    const props = defineProps({
        uploadedListSummary: {
            type: Object,
            required: true
        }
    });

    const hasAddressList = computed(() => {
        if (props.uploadedListSummary && props.uploadedListSummary?.total > 0)
            return true;
        return false;
    })

    const previewList = computed(() => {
        if (hasAddressList.value) {
            if (props.uploadedListSummary?.total <= 20) {
                return props.uploadedListSummary?.uploadedListItems;
            }
            else {
                return props.uploadedListSummary?.uploadedListItems.slice(0, 20)
            }
        }
        return [];
    });

    // Emits ----
    const emits = defineEmits(['back', 'next']);

</script>

<template>

    <div class="row my-5">

        <div class="col border-bottom border-color-1">

            <div class="d-flex">
                <div class="text-color-1">
                    <h3 class="mb-0"> Preview the address in your file</h3>
                </div>
            </div>

        </div>

    </div>

    <div class="row mb-5 mt-2">
        <div class="col">

            <div class="address-list  table-responsive">
                <template v-if="hasAddressList">
                    <h3 class="p-4">Compare these first {{ previewList.length }} records in this preview to your data file.</h3>
                    <table class="table table-striped w-auto mw-100">
                        <thead class="title">
                            <tr>
                                <th>Addressee</th>
                                <th>Address Line 1</th>
                                <th>Address Line 2</th>
                                <th>City</th>
                                <th>State</th>
                                <th>Zip</th>
                                <th>Country</th>
                                <th>PhoneNumber</th>
                                <th>Email</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="addr in previewList" :key="addr">
                                <td class="abbreviation">{{addr.address?.addressee}}</td>
                                <td class="abbreviation">{{addr.address?.addressLine1}}</td>
                                <td class="abbreviation">{{addr.address?.addressLine2}}</td>
                                <td class="abbreviation">{{addr.address?.city}}</td>
                                <td class="abbreviation">{{addr.address?.state ?? addr.address?.province}}</td>
                                <td class="abbreviation">{{addr.address?.postalCode}}</td>
                                <td class="abbreviation">{{addr.address?.countryCode}}</td>
                                <td class="abbreviation">{{addr.address?.phoneNumber}}</td>
                                <td class="abbreviation">{{addr.address?.email}}</td>
                            </tr>
                        </tbody>
                    </table>
                </template>
                <template v-else>
                    <slot name="empty"></slot>
                </template>
            </div>


        </div>
    </div>

    <div class="show-right">
        <PageButton class="me-2" :outline="true" @click="emits('back')">Back</PageButton>
        <PageButton class="me-2" @click="emits('next')" :disabled="!hasAddressList">Next</PageButton>
    </div>

</template>

<style lang="scss">

    .address-list {
        .title {
            font-size: inherit;
        }

        .table td.abbreviation {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .table td.abbreviation {
            max-width: 100px;
        }

        .mw-100 {
            min-width: 100%;
        }
    }
</style>