<script>
	// ProductListTile
	// A tile for a single product intended to be displayed in list mode.

	// Setup ---
	// Use the values below to modify how the tile appears
	const setup = {
		tileHeight: '8rem', // Height of the full tab, fixed for alignment purposes
		productNameLines: 2 // Number of lines product name is allowed to wrap before being trimmed with ellipses
	};

	export const Setup = setup;
</script>

<script setup>
	// Components ----
	import LimitMessages from './LimitMessages';

	// Imports ----
	import { computed, reactive, defineProps } from 'vue';
	import { AddToCartForm } from '@dd-nucleus/nucleus-vue';
	import { store } from '@/Store';

	// Props ----
	const props = defineProps({
		// The product whose information to show.
		// API model: DD.Nucleus.Storefront.Api.ProductTileModel
		product: {
			type: Object,
			required: true
		}
	});

	// State ----
	const product = reactive(props.product);
	const form = reactive(new AddToCartForm(product, '', null, product.allowBackorders));

	// Computed ----
	const quantityLabel = computed(() => {
		return store.config.store?.unitOfMeasures.find(unit => unit.id?.toLowerCase() === product.unitOfMeasure?.toLowerCase())?.text ?? null;
	});

	// Handlers ----
</script>

<template>
	<div class="product-list-tile" :style="{ minHeight: setup.tileHeight }">
		<div class="inner-row d-flex flex-row">
			<div class="left">
				<ProductThumbnail :product="product" :height="setup.tileHeight" width="200px" no-image-available-url="/images/no-image.png" />
			</div>
			<div class="middle flex-fill">
				<!-- Stretchy middle part-->

				<div class="tags mb-2">
					<ProductTags :product="product" />
				</div>

				<!-- Product name and item number -->
				<router-link class="title-link" :to="`/product/${product.id}`">
					<div class="name" v-snip="{ lines: setup.productNameLines }" :title="product.name">
						{{ product.name }}
					</div>
				</router-link>
				<div class="item-number">
					<router-link class="title-link" :to="`/product/${product.id}`">{{ product.itemNumber }}</router-link>
				</div>

				<LimitMessages :product="product" class="mt-2" />

				<!--<span v-if="showInStockQuantity && !product.allowBackorders" class="filter-messages">Qty in stock: {{ product.quantityAvailable }} </span>-->

				<div class="favorite">
					<ProductFavoriteIcon :product="product" label="Add as Favorite" />
				</div>
			</div>
			<!-- Fixed right panel -->
			<div class="right d-flex coming-soon" v-if="product.isAvailableSoon">
				<i class="bi bi-hourglass-split"></i>
				<i><p>Coming soon...</p></i>
			</div>
			<div class="right d-flex flex-column" :style="{ minHeight: setup.tileHeight }" v-else>
				<MessageList :messages="product.messages" :add-box="false" v-if="!product.allowBackorders" />
				<p>Quantity: {{ product.quantityPerUnit }} {{ product.quantityPerUnit > 1 ? 'units' : 'unit' }} per {{ quantityLabel }}</p>
				<FormContainer :form="form" v-if="product.canAddToCart">
					<div class="flex-fill">
						<ProductAddToCartQty :product="product" />
						<FormMessages field-name="quantity" :add-box="false" class="mt-2" v-if="!product.allowBackorders" />
						<FieldMessages field-name="quantity" class="mt-2" />
					</div>
					<div class="d-grid mt-3">
						<button class="btn btn-outline-color-1" @click="form.submit()" :disabled="form.status.isInvalid">ADD TO CART</button>
					</div>
				</FormContainer>
			</div>
		</div>
	</div>
</template>

<style lang="scss">
    .product-list-tile {
        .coming-soon {
            margin: auto;
            justify-content: center;
            align-items: center;

            .bi-hourglass-split {
                font-size: 1.25rem;
                margin-right: 0.75rem;
            }

            p {
                margin-bottom: 0;
                font-size: 1rem;
            }
        }

        p {
            font-size: 12px;
        }
        .inner-row {
            border-bottom: 1px solid $gray-20;
            padding-bottom: 1rem;
        }

        .name {
            font-size: 1rem;
            line-height: 1.1rem;
        }

        a.title-link,
        a.title-link:visited,
        a.title-link:hover {
            color: var(--bs-body-color);
            text-decoration: none;
        }

        .item-number a.title-link {
            font-size: 0.7rem;
            color: $gray-50;
        }

        .n-product-add-to-cart-qty .text-box {
            border-color: #dddddd;
        }

        .btn[disabled] {
            opacity: 0.2;
        }

        .middle {
            padding-left: 1rem;
        }

        .favorite {
            margin-top: 1rem;
        }

        .right {
            width: 14rem;
        }
    }
</style>
