<script setup>

    // MarketingContactTile
    // Customizes the standard AddressTile component with options/markup specific to marketing contacts.

    // Components ----

    // Imports ----
    import { reactive, defineProps, defineEmits, computed } from 'vue';
    import { store } from '@/Store';

    // Props ----
    const props = defineProps({
        // The contact to show
        contact: {
            type: Object,
            required: true
        },
        // An optional height to enforce.
        height: {
            type: String,
            required: false,
            default: null
        },
        // Whether to show a border around the tile.
        border: {
            type: Boolean,
            required: false,
            default: true
        },
        // An optional height to enforce for the address block.
        addressHeight: {
            type: String,
            required: false,
            default: null
        }
    });

    // State ----
    const contact = reactive(props.contact);

    // Computed ----
    const isFavorite = computed(() => {
        return contact.isFavorite;
    });

    // Events ----
    const emit = defineEmits(['edit', 'delete']);

    // Handlers ----
    function onEditClicked() {
        emit('edit');
    }

    function onDeleteClicked() {
        emit('delete');
    }

    function onFavoriteClicked() {
        store.marketing.setFavoriteContact(contact.id, !contact.isFavorite);
        contact.isFavorite = !contact.isFavorite;
    }

</script>

<template>

    <div class="marketing-contact-tile">
        <AddressTile :address="props.contact" :height="props.height" :address-height="props.addressHeight" :border="props.border">
            <template #top>
                <div class="float-end text-end"><FavoriteIcon :is-favorite="isFavorite" @click="onFavoriteClicked" /></div>
                <div class="name-block">
                    <div class="name">{{ props.contact.firstName }} {{ props.contact.lastName}}</div>
                    <div v-if="props.contact.email.length > 0" class="email">
                        <a :href="'mailto:' + props.contact.email">{{ props.contact.email }}</a>
                    </div>
                    <div v-if="props.contact.phoneNumber.length > 0" class="phone">{{ props.contact.phoneNumber}}</div>
                    <div v-if="props.contact.title.length > 0" class="title">{{ props.contact.title}}</div>
                </div>
            </template>

            <div class="mt-2">
                <ActionButton icon="bi bi-pencil-fill" label="Edit" @click="onEditClicked" />
                <ActionButton icon="bi bi-trash-fill" label="Delete" @click="onDeleteClicked" />
            </div>
        </AddressTile>
    </div>

</template>

<style lang="scss">

    .marketing-contact-tile {
        overflow-x: clip;

        .name-block {
            margin-bottom: 0.25rem;
        }

        .name {
            font-weight: $bold;
            font-size: 0.9rem;
            line-height: 1rem;
        }

        .title {
            font-style: italic;
        }

        .n-action-button {
            margin-right: 0.5rem;
        }
    }
</style>