<script setup>

    // CheckoutPage
    // Allows the user to view and change the shipping information for the cart.

    // Components ----
    import CartBodyPanel from './components/CartBodyPanel';
    import ShipTo from './components/ShipTo';
    import { computed, ref, onMounted } from 'vue';
    import { useRouter } from 'vue-router';
    import Popper from 'vue3-popper';

    // Imports ----
    import { store } from '@/Store';


    onMounted(async () => {
        await initializePage();
    });

    async function initializePage()
    {
        let result = await store.cart.checkIfCartContainsSunshineReportableItems(store.cart.current.id);
        isSunShineActReportable.value = result?.attributes?.isCartContainsSunshineReportableItems;
    }

    const router = useRouter();
    const reasonforexpedited = ref('');
    const isExpeditedReasonRequired = computed(() => {
        return store.cart.current.shipMethod?.isRush;
    });

    // sunshine items
    const doctorName = ref('');
    const doctorEmail = ref('');
    const isSunShineActReportable = ref('');

    const doctorNameErrorMessage = ref('');
    const doctorEmailErrorMessage = ref('');

    const disableContinue = computed(() => {
        // disable continue button
        // 1. when reason for expedited shipping is null or empty
        // 2. whem the doctor's name is null or empty
        // 3. when the doctor's email is invalid

        if (reasonforexpedited.value.trim().length < 1 && isExpeditedReasonRequired.value === true)
            return true;
        else if(isSunShineActReportable.value === true){
            if(doctorNameErrorMessage.value !== '' || doctorName.value.trim().length < 1 )
                return true;
            if(doctorEmail.value){
                if(doctorEmailErrorMessage.value) return true;
            }   
        }
        return false;
    });

    function ValidateDoctorName() {
        const textPattern = /^[^a-zA-Z]+$/;
        if (doctorName.value === null || doctorName.value === undefined || doctorName.value.length === 0) {
            doctorNameErrorMessage.value = 'Doctor Name cannot be empty';
        }
        else if (textPattern.test(doctorName.value)) {
            doctorNameErrorMessage.value = 'Doctor Name is invalid';
        }
        else {
            doctorNameErrorMessage.value = '';
        }
    }

    function ValidateDoctorEmail() {
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        if (doctorEmail.value && !emailPattern.test(doctorEmail.value)) {
            doctorEmailErrorMessage.value = 'Doctor Email is invalid';
        }
        else {
            doctorEmailErrorMessage.value = '';
        }
    }

    async function SaveReasonAndRoute() {
        SaveReasonforExpedited();

        if(isSunShineActReportable.value){
           await SaveDoctorsDetails();
        }
        router.push({ name: 'Review'});
     
    }

    async function SaveReasonforExpedited() {
    if (reasonforexpedited.value.length > 0 && isExpeditedReasonRequired.value === true) {
        const reasonNote = {
            holdReasonNote: reasonforexpedited.value.trim(),
            holdReasons: "Expedited Shipping"
        }
        await store.cart.addHoldReasonNote(store.cart.current.id, reasonNote);
    }
    }

    async function SaveDoctorsDetails() {
       
        if (doctorName.value.length > 0 && isSunShineActReportable.value === true) {
            const doctorDetails = {
                doctorName: doctorName.value.trim(),
                doctorEmail: (doctorEmail.value.trim() !== null) ? doctorEmail.value.trim() : ""
            }
        await store.cart.addDoctorDetails(store.cart.current.id, doctorDetails);
        }
    }


</script>

<template>
    <PageTemplate>
        <div class="container root-page checkout-page">

            <div class="float-end">
                <PageButton class="me-2" :outline="true" url="/cart">Back to Cart</PageButton>
                <PageButton v-if="!store.cart.isMultiShip()" class="me-2" @click="SaveReasonAndRoute" :disabled="disableContinue">Continue</PageButton>
            </div>

            <h1 class="cart-page-header mb-3">Checkout <span class="text-muted">/ Shipping</span></h1>

            <div class="row">
                <div class="col-12 col-md-9">
                    <CartBodyPanel class="cart-body-panel">
                        <template #title>
                            <h2>Ship To</h2>
                        </template>

                        <ShipTo :editable="true" :editing="true" :disableContinue="disableContinue" :reasonforexpedited="reasonforexpedited" :isExpeditedReasonRequired="isExpeditedReasonRequired" :isSunShineActReportable="isSunShineActReportable" :doctorName="doctorName" :doctorEmail="doctorEmail"/>

                    </CartBodyPanel>
                </div>

                <div class="col-12 col-md-3">
                    <CartBodyPanel class="cart-body-panel ship-method-panel">
                        <template #title>
                            <h2>Ship Method</h2>
                        </template>

                        <ShipMethodSelector v-model="store.cart.current.shipMethodCode" />

                        <div v-if="isExpeditedReasonRequired" class="mb-5 mt-3">
                            <h5 style="color: black;">Reason for Expedited Shipping</h5>
                            <textarea v-model="reasonforexpedited" maxlength="255" class="form-control" rows="5" placeholder="Please enter reason for Expedited Shipping"></textarea>
                        </div>
                        <div v-if="isSunShineActReportable" class="mb-5 mt-3">
                            <div class="sunshine-act-message-box ">
                                Your cart includes items that are Sunshine Act Reportable. Please enter the name and email address of the doctor receiving the items.
                            </div>
                            <br />
                            <div>
                                <div>
                                    <label for="flexCheckDefault">
                                        Doctor's Name
                                        <Popper arrow>
                                            <i class="info-icon bi bi-info"></i>
                                            <template #content>
                                                <div class="popper-content">
                                                    Type the first and last name(s) of the doctor receiving the Sunshine Act Reportable Item(s). This is required to proceed.
                                                </div>
                                            </template>
                                        </Popper>
                                    </label>
                                    <br />
                                    <input class="form-control" type="text" v-model="doctorName" maxlength="50" @input="ValidateDoctorName" placeholder="Doctor's Name" />
                                    <p v-if="doctorNameErrorMessage" class="error-message">{{doctorNameErrorMessage}}</p>
                                </div>

                                <br />
                                <div>
                                    <label for="flexCheckDefault">Doctor's Email (Optional)</label>
                                    <input class="form-control" type="email" v-model="doctorEmail" maxlength="50" @input="ValidateDoctorEmail" placeholder="Doctor's Email" />
                                    <p v-if="doctorEmailErrorMessage" class="error-message">{{doctorEmailErrorMessage}}</p>
                                </div>
                            </div>
                        </div>
                    </CartBodyPanel>
                </div>
            </div>

        </div>
    </PageTemplate>
</template>

<style lang="scss">

    .root-page {
        min-height: 30rem;
    }

    .checkout-page {
        .n-body-panel {
            .body {
                padding: 0.75rem 0rem;
            }
        }

        .ship-method-panel {
            .body {
                padding-left: 1.5rem;
            }
        }

        .sunshine-act-message-box {
            border: 0.5px solid #f2dad4;
            padding: 5px;
            //width: 180px;
            text-align: left;
            background-color: #fff3cd;
        }
    }
    .info-icon {
        font-size: 12px;
        margin-left: 4px;
        cursor: pointer;
        background: lightgray;
        border-radius: 16px;
    }

    .error-message {
        font-size: 13px !important;
        margin-top: 0 !important;
        color: red !important;
    }

</style>