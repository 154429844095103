<script setup>

    // ShipToUser
    // Allows the user to ship to a new address, pick from an existing address, or edit existing addresses, if store options for doing so are enabled.

    // Constants ----
    const View = {
        EDITABLE: 'EDITABLE',
        EDITING: 'EDITING',
        SUMMARY: 'SUMMARY'
    }

    const AddressOption = {
        SAVED_ADDRESS: 'SAVED',
        OTHER: 'OTHER'
    }

    // Components ----
    import ShippingAddressTile from '@/site/components/ShippingAddressTile';

    // Imports ----
    import { reactive, ref, defineProps, computed, onMounted, defineEmits, watch } from 'vue';
    import { store, DEFAULT_COUNTRY_CODE } from '@/Store';
    import ShipToUserForm from '../forms/ShipToUserForm';
    import { Search, ShippingAddressForm } from '@dd-nucleus/nucleus-vue';

    // Props ----
    const props = defineProps({
        // The label to show with the icon on the button.
        canEdit: {
            type: Boolean,
            required: true
        }
    });

    // State ----
    let addShippingAddressForm = new ShippingAddressForm();
    const form = reactive(new ShipToUserForm());
    const cart = reactive(store.cart.current);
    const addressOption = ref('');
    const selectedAddressId = ref('');
    const isLoaded = ref(false);
    const addingShippingAddress = ref(false);
    const selectedCountry = ref(DEFAULT_COUNTRY_CODE);
    const emit = defineEmits(['addressType']);

    const search = new Search('addresses-for-user', 'tile', 'address-sequence', 1000);
    search.setEmptyFilter('address-shipping');

    const options = [
        { id: AddressOption.SAVED_ADDRESS, text: 'Saved Address' },
        { id: AddressOption.OTHER, text: 'Other Address' }
    ];

    // Computed ----
    const pageView = computed(() => {
        if (props.canEdit) {
            return View.EDITING;
        }
        else
            // Summary view - display only
            return View.SUMMARY;
    })

    const cartAddressOption = computed(() => {
        return addressOption.value;
    });

    // Methods ----

    // Handlers ----
    onMounted(() => {
        update();
        emit('addressType', { addValue: addressOption.value, addressId: selectedAddressId.value });
    });

    store.cart.onCartLoaded(() => {
        update();
    });

    store.cart.onCartUpdated(() => {
        update();
    });

    // Called once we know we have a cart to work with
    function update() {
        if (typeof store.cart.current.shipToList === 'undefined') return;

        let contactAddressId = store.cart.current.shipToList.length > 0 ? store.cart.current.shipToList[0].contactAddressId : ''

        if (store.cart.current.shipToMode === store.cart.ShipToMode.ShipToUser)
            selectedAddressId.value = contactAddressId;
        else
            selectedAddressId.value = '';

        if (cart.shipToMode === store.cart.ShipToMode.ShipToUser && contactAddressId === null) {
            addressOption.value = AddressOption.OTHER;

            const shipTo = store.cart.current.shipToList[0];
            form.model.companyName = shipTo.companyName;
            form.model.addressLine1 = shipTo.addressLine1;
            form.model.addressLine2 = shipTo.addressLine2;
            form.model.addressLine3 = shipTo.addressLine3;
            form.model.addressLine4 = shipTo.addressLine4;
            form.model.city = shipTo.city;
            form.model.state = shipTo.state;
            form.model.postalCode = shipTo.postalCode;
            form.model.countryCode = shipTo.countryCode ? shipTo.countryCode : selectedCountry.value;
        }
        else
            addressOption.value = AddressOption.SAVED_ADDRESS;

        addShippingAddressForm.model.countryCode = form.model.countryCode = selectedCountry.value;
        isLoaded.value = true;
    }

    function onAddShippingAddress() {
        addShippingAddressForm.model.countryCode = selectedCountry.value;
        addingShippingAddress.value = true;
    }

    function onCancelAddShippingAddress() {
        addShippingAddressForm.reset();
        addingShippingAddress.value = false;
    }

    async function onSaveShippingAddress() {
        const succeeded = await addShippingAddressForm.submit();
        if (succeeded) {
            addingShippingAddress.value = false;
            addShippingAddressForm.reset();
        } else {
            addShippingAddressForm.validate();
        }

        await store.refreshSearches('addresses-for-user');
    }

    async function onAddressSelected(address) {
        await store.cart.setShipToUserSavedAddress(address.id);
        store.refreshSearches('addresses-for-user');
        addShippingAddressForm.model.countryCode = selectedCountry.value;
    }

    async function onShippingAddressChanged() {
        if (selectedCountry.value != addShippingAddressForm.model.countryCode) {
            selectedCountry.value = addShippingAddressForm.model.countryCode;
            addShippingAddressForm.model.state = '';
            addShippingAddressForm.model.province = '';
        }
    }
    async function onOtherAddressChanged() {
        // Call the form to update the address, but we don't want validation to prevent submission.
        if (selectedCountry.value != form.model.countryCode) {
            selectedCountry.value = form.model.countryCode;
            form.model.state = '';
            form.model.province = '';
        }
        await form.submit(false);
    }

    watch(
        () => [form, addressOption.value, selectedAddressId.value],
        () => {
            if (addressOption.value === AddressOption.OTHER) {
                emit('addressType', { addValue: addressOption.value, addressId: null, formIsValid: !form.validator.isInvalid });
            } else emit('addressType', { addValue: addressOption.value, addressId: selectedAddressId.value });

        },
        {
            deep: true
        }
    );

</script>

<template>
    <div class="ship-to-user">

        <div v-if="pageView === View.EDITABLE" class="p-3">

            <ShippingAddressTile v-if="isLoaded" :address="store.cart.current.shipToList[0]" :border="false">
            </ShippingAddressTile>

        </div>

        <template v-if="pageView === View.EDITING">
            <div class="mb-3">
                <RadioButton v-for="option in options" :key="option.id" v-model="addressOption" :label="option.text"
                             list-id="address-option" :value="option.id" class="d-inline-block me-3" />
            </div>

            <div v-if="cartAddressOption === AddressOption.SAVED_ADDRESS">
                <SearchContainer :search="search" :deep-link="false">

                    <SearchGrid :columns-xs="1" :columns-sm="2" :columns-md="2" :columns-lg="3" :columns-xl="3"
                                :columns-xxl="3">
                        <!-- Template for each item in a grid view -->
                        <template v-slot:grid="address">

                            <TileSelectButton :item="address" :multi-select="false"
                                              :is-selected="address.id === selectedAddressId" @selected="onAddressSelected(address)" />
                            <ShippingAddressTile :class="{ selected: address.id === selectedAddressId }" :address="address"
                                                 :can-set-default="true" height="11rem">
                            </ShippingAddressTile>

                        </template>
                    </SearchGrid>

                </SearchContainer>
                <div class="mt-1">
                    <ActionButton icon="bi bi-plus-lg" label="Add Shipping Address" @click="onAddShippingAddress" />
                </div>

                <div v-if="addingShippingAddress" class="subform mt-2" ref="shippingAddressForm">
                    <div class="col-12 col-md-8">
                        <div class="header">New Shipping Address</div>
                        <EditShippingAddress :form="addShippingAddressForm" :address-only="true" :start-address-lines="2"
                                             :max-address-lines="2" :selectedCountry="selectedCountry" @change="onShippingAddressChanged">
                            <div class="row">
                                <div class="col-12 pt-2">
                                    <button class="btn btn-outline-color-2 me-2"
                                            @click="onCancelAddShippingAddress">
                                        Cancel
                                    </button>
                                    <button class="btn btn-color-2" @click="onSaveShippingAddress"
                                            :disabled="addShippingAddressForm.status.isInvalid">
                                        Save
                                    </button>
                                </div>
                            </div>
                        </EditShippingAddress>
                    </div>
                </div>
            </div>

            <div v-if="cartAddressOption === AddressOption.OTHER">
                <div class="col-12 col-md-9 col-lg-6">
                    <EditShippingAddress :form="form" @change="onOtherAddressChanged" :address-only="true"
                                         :start-address-lines="2" :max-address-lines="2" :selectedCountry="selectedCountry">
                        <template #top>
                            <div class="n-form-label mb-2">
                                <label for="61d18332-3ce4-487f-bc66-b24b0e5a579b" class="label form-label">Ship To</label>
                                <div class="n-text-box-field">
                                    <input id="61d18332-3ce4-487f-bc66-b24b0e5a579b" type="text"
                                           class="text-box form-control" v-model="form.model.addressee"
                                           @change="onOtherAddressChanged">
                                </div>
                            </div>
                        </template>
                        <div class="mt-3">
                            <CheckBoxField v-model="form.model.saveAddress" @change="onOtherAddressChanged"
                                           label="Save this address for future orders" />
                            <CheckBoxField v-if="form.model.saveAddress" v-model="form.model.saveDefault"
                                           @change="onOtherAddressChanged" label="Save as my default shipping address" />
                        </div>
                    </EditShippingAddress>
                </div>
            </div>
        </template>

        <template v-if="pageView === View.SUMMARY">

            <ShippingAddressTile v-if="isLoaded" :address="store.cart.current.shipToList[0]" :border="false">
            </ShippingAddressTile>

        </template>
    </div>
</template>

<style lang="scss">
    .ship-to-user {
        .n-tile-select-button {
            position: absolute;
            margin-top: -0.5rem;
            margin-left: -0.25rem
        }

        .shipping-address-tile.selected .n-address-tile {
            border: 1px solid $color-1 !important;
        }
    }
</style>