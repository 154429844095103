<script setup>

    // OrderShipToMultiple
    // Displays ship-to information when using multiship

    // Imports ----
    import { defineProps, ref } from 'vue';

    // Components ----
    import MultiShipDetails from './MultiShipDetails';

    // Props ----
    const props = defineProps({
        // The order whose ship-to information to show
        order: {
            type: Object,
            required: true
        }
    });


    function cssClass(order) {
        // TODO: Hacked in. Create constants in Orders service plugged into singleton store object.
        switch (order.orderStatusCode) {
            default: // None
                return '';
            case 1: // Pending
                return 'pending';
            case 2: // In Progress
                return 'inprogress';
            case 99: // Canceled
                return 'canceled';
            case 100: // Completed
                return 'completed';
        }
    }

    function icon(order) {
        // TODO: Hacked in. Create constants in Orders service plugged into singleton store object.
        switch (order.orderStatusCode) {
            default: // None
                return '';
            case 1: // Pending
                return 'bi bi-check-circle-fill';
            case 2: // PendingApproval
                return 'bi bi-pause-circle-fill';
            case 99: // Canceled
                return 'bi bi-x-circle-fill';
            case 100: // Completed
                return 'bi bi-check-circle-fill';
        }
    }

    // State ----
    const isOpen = ref(false);

    const removeAddressLine3 = function (shipTo) {
        delete shipTo.addressLine3
        return shipTo;
    };
        
    // Methods ----
    function expandAll() {
        isOpen.value = true;
    }

    function collapseAll() {
        isOpen.value = false;
    }



</script>

<template>

    <div class="order-ship-to-multiple">



        <div class="d-flex mb-3">
            <div>
                <h2>Shipping Address & Quantities</h2>
            </div>
            <div class="ms-auto">
                <a v-if="!isOpen" href="#" class="fs-2" @click="expandAll">Expand All</a>
                <a v-else href="#" class="fs-2" @click="collapseAll">Less Details</a>
            </div>
        </div>



        <div class="accordion accordion-flush" v-for="(ship,index) in props.order" :key="index">

            <div class="accordion-header" :id="'flush-heading'+index">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#flush-collapse'+index" aria-expanded="false" :aria-controls="'flush-collapse-'+index">
                    <div class="flex-fill">
                        <AddressTile :address="removeAddressLine3(ship.shipTo)" :border="false" />
                    </div>
                    <div class="flex-end m-2">
                        <span>Order # {{ ship.orderNumber }} </span> | <span class="status" :class="cssClass(ship)"><i :class="icon(ship)"></i> {{ ship.orderStatus }}</span>
                    </div>
                </button>
            </div>
            <div :id="'flush-collapse'+index" :class="{ show: isOpen }" class="accordion-collapse collapse" :aria-labelledby="'flush-heading'+index" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">
                    <MultiShipDetails :order="ship" />
                </div>
            </div>

        </div>

    </div>




</template>

<style lang="scss">

    .order-ship-to-multiple {
        .accordion-button:not(.collapsed) {
            color: unset;
            background-color: unset;
            box-shadow: unset;
        }

        .accordion-button {
            background: unset;
            display: flex;
            justify-content: space-between;
        }

        .accordion:nth-child(even) {
            background: $neutral-lt;
        }

        .ship-type-title {
            font-size: 0.9rem;
            font-weight: $bold;
        }

        .status {
            margin-top: 0.5rem;
            font-weight: $bold;
            font-size: 0.7rem;
        }

        .status.pending {
            .bi {
                opacity: 0.5;
                color: $ok;
            }
        }

        .status.inprogress {
            .bi {
                color: $warning;
            }
        }

        .status.pending-approval {
            .bi {
                color: $warning;
            }
        }

        .status.canceled {
            .bi {
                color: $error;
            }
        }

        .status.completed {
            .bi {
                color: $ok;
            }
        }
    }
</style>