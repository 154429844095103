<script setup>
    import { store } from '@/Store';
    import { ref, defineProps, defineEmits, watch } from 'vue';
import Popper from 'vue3-popper';

//import { Util } from '@dd-nucleus/nucleus-vue'

// Props ----
const props = defineProps({
    // The cart item
    title: {
        type: String,
        required: true
    },
    checkContinueStatus: {
        type: Function,
        required: true
    }
});

const isChecked = ref(false);  
const reasonforConferenceOrder = ref('');
const isConferenceOrderType = ref(store.cart.current.attributes?.allowLimitOverride === "true");
const emit = defineEmits(['textchange']);

    async function updateConferenceType() {
        if (!isChecked.value)
            reasonforConferenceOrder.value = '';
        await store.cart.overrideProductLimits(isChecked.value);
        updatecontinueStatus();
    }
    async function updatecontinueStatus() {
        if (isChecked.value && !reasonforConferenceOrder.value.trim()) {
            props.checkContinueStatus(true);
        } else {
            props.checkContinueStatus(false);
        }
    }

store.cart.onCartLoaded(() => {
    isConferenceOrderType.value = store.cart.current.attributes?.allowLimitOverride === "true";
});

store.cart.onCartUpdated(() => {
    isConferenceOrderType.value = store.cart.current.attributes?.allowLimitOverride === "true";
});

    watch(reasonforConferenceOrder, (newValue) => {
        updatecontinueStatus();
        emit('textchange', newValue);
    });
   
</script>

<template>
    <div class="form-check mt-2">
        <input class="form-check-input" type="checkbox" v-model="isChecked" :checked="isConferenceOrderType" @change="updateConferenceType">
        <label class="form-check-label" for="flexCheckDefault">
            {{ props.title }}
            <Popper arrow>
                <i class="info-icon bi bi-info"></i>
                <template #content>
                    <div class="popper-content">
                        By checking this box, limits applied on the product will be bypassed. <br /><br /><span style="font-weight: bold;">Note:</span> Quantity added to the cart will be dependant on the
                        quantity available in the stock.
                    </div>
                </template>
            </Popper>
        </label>
        <div v-if="isChecked" class="mt-3">
            <h5 style="color: black;">Reason for Conference Order</h5>
            <textarea v-model="reasonforConferenceOrder" maxlength="255" class="form-control" rows="5" placeholder="Please enter reason for Conference Order"></textarea>
        </div>
    </div>
</template>

<style lang="scss">
.info-icon {
    font-size: 12px;
    margin-left: 4px;
    cursor: pointer;
    background: lightgray;
    border-radius: 16px;
}
</style>