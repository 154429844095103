<script setup>
//CRMContacts
// Allows a user to select contact(s) from their customer addresses.

// Constants ----
const constants = {
    DEFAULT_PAGE_SIZE: 12
};

// Components ----
import CRMContactTile from './CRMContactTile';

// Imports ----
import { defineProps, defineEmits, ref } from 'vue';
import { Search } from '@dd-nucleus/nucleus-vue';

// Props ----
const props = defineProps({
    // Allow creation of a contact?
    allowCreate: {
        type: Boolean,
        required: false,
        default: true
    },
    // Whether to use a large, primary color button rather than an ActionButton for creating.
    largeCreateButton: {
        type: Boolean,
        required: false,
        default: false
    },
    // Whether this displaying to allow a selection
    canSelect: {
        type: Boolean,
        required: false,
        default: false
    },
    // If allowing selection, whether we're allowing multi-selection
    multiSelect: {
        type: Boolean,
        required: false,
        default: false
    },
    // If allowing selection, an array to store selected ID(s)
    selection: {
        type: Array,
        required: false,
        default: () => []
    },
    // Indicates whether the selection can be toggled on/off
    toggle: {
        type: Boolean,
        required: false,
        default: false
    }
});

// State ----
const search = new Search('crm-addresses-for-user', 'tile', 'crmaddress-newest-to-oldest', constants.DEFAULT_PAGE_SIZE);
//const selectedCountry = ref(DEFAULT_COUNTRY_CODE);

const selectedContactId = ref('');

const sortOptions = [
    { id: 'crmaddress-newest-to-oldest', text: 'Newest to Oldest' },
    { id: 'crmaddress-oldest-to-newest', text: 'Oldest to Newest' }
];

const pageSizeOptions = [12, 24, 36, 48, 60];

// Computed ----

// Events ----
const emit = defineEmits(['selected', 'created', 'deselected']);

// Handlers ----

function onContactSelected(contact) {
    selectedContactId.value = contact.id;
    emit('selected', contact);
}

function onContactDeselected(contact) {
    selectedContactId.value = '';
    emit('deselected', contact);
}

</script>

<template>
    <SearchContainer :search="search" :deep-link="false">

        <div class="mb-3">
            <LetterPickFilter filter-id="crm-address-keywords" class="mb-2 d-none d-lg-block" />
            <div>
                <TextBoxFilter :resetText="false" filter-id="crm-address-keywords"
                    placeholder="Search last name, first name, company name, address, email, or phone" />
            </div>
        </div>

        <div v-show="search.results.count > 0" class="row mb-3">
            <div class="col">
                <SortOptionsList :options="sortOptions" />
            </div>
            <div class="col">
                <PageSizeList :options="pageSizeOptions" />
            </div>
            <div class="col-4 text-end">
                <PageSelector />
            </div>
        </div>

        <SearchGrid :columns-xs="1" :columns-sm="2" :columns-md="2" :columns-lg="3" :columns-xl="3" :columns-xxl="3">
            <!-- Template for each item in a grid view -->
            <template v-slot:grid="contact" v-if="props.multiSelect">
                <!-- TODO: is-Selected is not working when we select multiselect-->
                <div class="marketing-contacts">
                    <TileMultiSelectButton v-if="props.canSelect" :item="contact" :multi-select="props.multiSelect"
                        :is-selected="selection.includes(contact.id)" @selected="onContactSelected(contact)"
                        @deselected="onContactDeselected(contact)" :toggle="props.toggle" />
                    <CRMContactTile :class="{ selected: selection.includes(contact.id) }" :contact="contact"
                        addressHeight="10rem" @edit="onEditContact(contact.id)" @delete="onDeleteContact(contact.id)" />
                </div>
            </template>

            <template v-slot:grid="contact" v-else>
                <div class="marketing-contacts">
                    <TileSelectButton :item="contact" :multi-select="false"
                        :is-selected="selectedContactId === contact.id" @selected="onContactSelected(contact)"
                        @deselected="onContactDeselected(contact)" />
                    <CRMContactTile :class="{ selected: selectedContactId === contact.id }" :contact="contact"
                        addressHeight="10rem" @edit="onEditContact(contact.id)" @delete="onDeleteContact(contact.id)" />
                </div>
            </template>

            <!-- Template for no results -->
            <template #empty>
                No contacts have been created for your account.
            </template>

            <!-- Template while results are initially loading -->
            <template #loading-grid>
                <MockSearchGrid>
                    <!-- TODO: Add mock contact tile -->
                </MockSearchGrid>
            </template>
        </SearchGrid>

        <div class="row mb-3">
            <div class="col-4 text-end">
                <PageSelector />
            </div>
        </div>
    </SearchContainer>
</template>

<style lang="scss">
.marketing-contacts {

    // TODO: Move this more central
    .nav-tabs .bi-heart {
        margin-right: 0.3rem;
    }

    .n-tile-select-button {
        position: absolute;
        margin-top: -0.5rem;
        margin-left: -0.25rem;
    }

    .marketing-contact-tile.selected .n-address-tile {
        border: 1px solid $color-1 !important;
    }

    .n-button-bar {
        .btn:hover {
            color: $button-bar-selected-text-color;
            background-color: $button-bar-selected-bg-color;
        }
    }
}
</style>
